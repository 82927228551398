export const schemeUrl = {
  profile: 'tmap://setting-myinfo',
  point: 'tmap://paypoint',
  biz_place: 'tmap://biz-place',
}

export const envServiceUrl = {
  tmapBizInfo: {
    dev: 'https://tmobi-board-dev.tmapmobility.com/BOARD-STATIC/61',
    stg: 'https://tmobi-board-stg.tmapmobility.com/BOARD-STATIC/90',
    prd: 'https://board.tmapmobility.com/BOARD-STATIC/9',
  },
}

export interface BizPlaceBannerType {
  isShow: boolean
  url: string
}

export const staticBizPlaceBanner: BizPlaceBannerType = { isShow: true, url: 'tmap://biz-place' }

export interface GroupListType {
  name: string
  url: string
  osType?: Array<string>
  redDotItem?: string
  log_action_id?: string
  minVersion?: string
  maxVersion?: string
}

export interface ServiceListType {
  groupName: string
  groupCode: string
  groupList: GroupListType[]
}

export const staticServiceList: ServiceListType[] = [
  {
    groupName: '결제관리',
    groupCode: 'pay_manage',
    groupList: [
      {
        name: '이용내역',
        url: 'tmap://usage-history',
        log_action_id: 'tap.usage',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '결제관리',
        url: 'payment',
        log_action_id: 'tap.pay',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '제휴쿠폰',
        url: 'tmap://promotion',
        log_action_id: 'tap.coupon',
        osType: ['AOS', 'iOS'],
      },
    ],
  },
  {
    groupName: '서비스 모아보기',
    groupCode: 'service_list',
    groupList: [
      {
        name: '전체 서비스',
        url: 'tmap://vertical?service=my&pageid=all_services',
        redDotItem: 'HOME',
        log_action_id: 'tap.whole_service',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '비즈 플레이스',
        url: 'tmap://board?pageid=board_detail&boardid=BOARD-MAIN&seq=412',
        log_action_id: 'tap.biz_place',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '연구소',
        url: 'tmap://setting-laboratory',
        redDotItem: 'LABORATORY',
        log_action_id: 'tap.laboratory',
        osType: ['AOS', 'iOS'],
      },
    ],
  },
  {
    groupName: '고객지원',
    groupCode: 'customer_support',
    groupList: [
      {
        name: '고객센터',
        url: 'tmap://cs',
        redDotItem: 'VOC',
        log_action_id: 'tap.voc',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '공지사항/이벤트',
        url: 'tmap://communication',
        redDotItem: 'COMMUNICATION',
        log_action_id: 'tap.communication_event',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '내비 업데이트 소식',
        url: 'tmap://board?pageid=board_list&boardid=BOARD-NAVIGATION-UPDATE-V2',
        redDotItem: 'SERVICE_INDEX',
        log_action_id: 'tap.navigation_update',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '장소제안',
        url: 'tmap://tip-off',
        log_action_id: 'tap.poi_proposal',
        osType: ['AOS', 'iOS'],
      },
      {
        name: '약관',
        url: 'tmap://webview?service=policy&id=1&params=termsCode%3DLIST-TERMS-TMAP-MAIN%26appCode%3DTMAP%26headerYn%3Dy%26headerTitle%3D%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80',
        log_action_id: 'tap.policy',
        osType: ['AOS', 'iOS'],
      },
    ],
  },
]

export interface InsuranceBannerType {
  bodyText: string
  btnText: string
  subText: string
  url: string
}

export const staticInsuranceBanner: InsuranceBannerType = {
  bodyText: '자동차 보험이 곧 만료되나요?',
  btnText: '내 운전점수로 할인율 비교하기',
  subText: '* 내 차 등록일 기준',
  url: 'tmap://tscore?pageid=benefit&extra=%7b%22is_first_history%22%3atrue%2c%22logbox%22%3a%7b%22origin%22%3a%22my%22%2c%22type%22%3a%22banner%22%7d%2c%22targetScheme%22%3a%22tscore%22%7d&top=true',
}
